.swiper-pagination {
    position: relative !important;
}

.swiper-slide {
    margin-bottom: 10px;
}

.swiper-pagination-bullet-active {
    background: #62824D !important; /* Change #0000ff to your desired color */
}